
  .about {
    text-align: center;
    font-size: 4rem !important;
    margin-bottom: 3rem !important;
  }


  .about-image {
    text-align: center;
    margin-top: 50px !important;
  }

  .mySwiper {
    gap: 1.2rem;
    padding: 1.5rem;
    width: 100%;
  }

  @media (max-width: 960px) {

    .hard-skills {
      justify-content: center;
    }
    .about-image {
      display: flex;
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      max-width: 100%;
      margin-top: 4rem;
    }

    .about {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.5rem;
    }

    .location {
      font-size: 1.2rem;
    }
  }

