.modal-content {
  background-color: transparent;
  border-radius: 12px;
  height: auto;
  width: auto;
  border: 4px solid #fd651b;
}

.modal-data {
  display: flex;
  background-color: #0d315f;
  transform: rotate(-3deg);
  border-radius: 12px;
  height: auto;
  color: #fff;
}

.close-button {
  background-color: #fff;
  width: 80px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-text {
  display: flex;
  transform: rotate(3deg);
}

.text {
  margin: 0 2rem 0 0;
  display: flex;
  flex-direction: column;
}

.model-title {
  font-size: 3.5rem;
  margin: 2rem;
}

.discount-paragraph {
  font-size: 2.7rem;
  text-align: center;
  span {
    color: #fd651b;
    font-size: 3.4rem;
    font-weight: bold;
  }
}

.zane-paragraph {
  font-size: 1.8rem;
  text-align: center;
}

.modal-button {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 50%;
  height: 50px;
  font-size: 1.8rem;
  font-weight: bold;
}

.date-paragraph {
  font-size: 1.8rem;
  text-align: center;
}

.modal-image {
  margin-top: -2.3rem;
  margin-right: -0.9rem;
  width: 60%;
  img {
    transform: rotate(-3deg);
    border-radius: 0 12px 12px 0;
  }
}

.modal-mobile-image {
  display: none;
}

.modal-image img,
.modal-mobile-image img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Or use 'cover' depending on your design needs */
}

@media screen and (max-width: 760px) {
  .modal-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-mobile-image {
    display: block;
    min-height: 220px;
    img {
      height: 100%;
      transform: rotate(0deg);
      border-radius: 12px;
    }
  }

  .modal-image {
    display: none;
  }

  .modal-data {
    position: relative;
    transform: rotate(0deg);
    margin: 2px;
    height: auto;
  }

  .modal-text {
    transform: rotate(0deg);
  }

  .text {
    /* margin: 0 2rem 0 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .model-title {
    font-size: 2.6rem;
  }

  .discount-paragraph {
    font-size: 1.9rem;
    span {
      font-size: 2.4rem;
    }
  }

  .zane-paragraph {
    font-size: 1.3rem;
  }

  .modal-button {
    width: 50%;
    height: 30px;
    font-size: 1.3rem;
  }

  .date-paragraph {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }

  .close-button {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    border-radius: 6px;
    cursor: pointer;
  }
}

@media screen and (max-width: 460px) {
  .modal-image {
    display: none;
  }

  .model-title {
    font-size: 2.5rem;
  }

  .discount-paragraph {
    font-size: 1.6rem;
    span {
      font-size: 1.8rem;
    }
  }

  .zane-paragraph {
    font-size: 1.2rem;
  }

  .modal-button {
    width: 50%;
    height: 30px;
    font-size: 1.2rem;
  }

  .date-paragraph {
    font-size: 1.2rem;
    margin: 2rem;
  }
}
