.actor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.actor-img {
  width: 130px !important;
  height: 130px !important;
  object-fit: cover;
  border-radius: 100px;
  border: 2px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.h3 {
  margin-top: 2rem;
  color: var(--orange) !important;
  font-size: 2rem;
  text-align: center;
}
.p {
  font-size: 1.8rem;
  /* letter-spacing: 0.1rem; */
  font-weight: 500;
  text-align: center;
  
}
.location {
  font-size: 1.4rem;
  color: #666;
  text-align: center;
}

@media (max-width: 960px) {
  .actor-img {
    width: 120px !important;
    height: 120px !important;
  }
}

@media only screen and (max-width: 480px) {
  .actor-img {
    width: 90px !important;
    height: 90px !important;
  }
}
